import SectionTitle from "../../components/SectionTitle";
import {TITLES} from "../../models/constants/ConstantsUI";
import president from '../../assets/img/ui/president.jpg';
import CustomImage from "../../components/CustomImage";
import PresidenciaActividades from "./PresidenciaActividades";

const Presidency = () => {
    return (
        <>
            <SectionTitle title={TITLES.PRESIDENCY}/>
            <div className='container my-5 mx-auto'>
                <div className='mx-5'>
                    <h3 className='title-section-2 fg-2'>PRESENTACIÓN</h3>
                    <div className='row'>
                        <div className='col-xxl-8 col-sm-12 fs-1'>
                            <p className='description-1'>
                            Andrónico Rodríguez nació el 11 de noviembre de 1988 en San Isidro, Sacaba, en el departamento de Cochabamba, Bolivia.
Es hijo de un productor cocalero que formaba parte de un sindicato en el Trópico de Cochabamba. En 1996, la familia se mudó
a Entre Ríos debido a las necesidades laborales de su padre, y allí Andrónico inició su educación escolar, finalizando el 
bachillerato en 2006 en la Unidad Educativa José Carrasco. Desde joven, asistía a las reuniones sindicales de su padre y 
decidió estudiar ciencias políticas para adquirir el conocimiento necesario para ayudar a su comunidad con mayor eficacia. 
Se matriculó en la Universidad Mayor de San Simón en Cochabamba, graduándose como politólogo en 2011. Su implicación en 
las Seis Federaciones Cocaleras comenzó pronto, llegando a ser presidente en 2012. En 2017, asumió el cargo de secretario 
de Deportes y en 2018 se convirtió en vicepresidente. Además de su trabajo en la producción de coca, apoya a su familia en 
el Chapare con cultivos de piña, arroz y piscicultura. En las elecciones generales de 2019, fue candidato a senador por 
Cochabamba del Movimiento Al Socialismo y desempeñó un papel destacado al presentar a Evo Morales en el cierre de su 
campaña electoral y en la inauguración de su casa de campaña en Buenos Aires. Tras la dimisión de Morales en medio de 
disturbios sociales, Rodríguez instó a movilizaciones para que se respetara el mandato del presidente hasta el 22 de enero 
de 2020. El 16 de noviembre de 2019, se difundió erróneamente en Twitter que Rodríguez había sido secuestrado por las Fuerzas Armadas bolivianas.
                                </p>
                            <p className='description-1'>
                                
                            </p>
                        </div>
                        <div className='col-xxl-4 col-sm-12'>
                            <CustomImage className='img-fluid' src={president} />
                        </div>
                    </div>
                </div>
            </div>

            <PresidenciaActividades />
        </>
    );
};
export default Presidency;