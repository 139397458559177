import './sticky-media.css';
import MediaLink from "./MediaLink";
import shortId from "shortid";

const buttons = [
    {
        mediaIconClass: 'fab fa-facebook-f',
        url: 'https://www.facebook.com/SenadoBolivia',
        extraClazz: 'bg-white fg-2',
    },
    {
        mediaIconClass: 'fab fa-youtube',
        url: 'https://www.youtube.com/channel/UCLPWAY7_UfAmQu1FbJhBh_Q/featured',
        extraClazz: 'bg-white fg-2',
    },
    {
        mediaIconClass: 'fab fa-twitter',
        url: 'https://twitter.com/MindeGobierno',
        extraClazz: 'bg-white fg-2',
    },
    {
        mediaIconClass: 'fab fa-instagram',
        url: 'https://instagram.com/mingobierno',
        extraClazz: 'bg-white fg-2',
    },
    {
        mediaIconClass: 'fab fa-tiktok',
        url: 'https://instagram.com/mingobierno',
        extraClazz: 'bg-white fg-2',
    }
];

const StickyMediaButtons = () => {
    return (
        <div className='sticky-social'>
            <div className='d-flex flex-column justify-content-center'>
                {buttons.map(button => <div key={shortId()} className='pb-1'><MediaLink {...button} /></div>)}
            </div>
        </div>
    );
};
export default StickyMediaButtons;
